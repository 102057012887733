import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"

import { Container, Row, Col, Image } from "react-bootstrap"
import { Helmet } from "react-helmet"

import Header from "../../../components/Header"
import Footer from "../../../components/Footer"

import { useGlobalState } from "../../../global/global"
import { getLanguageFile } from "../../../global/langauge.helper"
import { TextFormater } from "../../../global/textFormater"

import NGINR_IMG from "../../../../assets/images/products_nginr.png"
import NGINRS_IMG from "../../../../assets/images/nginrSFigure.png"

import "./styles.scss"

const NginrsPage = () => {
  const [state] = useGlobalState()

  return (
    <Container fluid className="main-container">
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Nginr®</title>
        </Helmet>
      </div>
      <Header />
      <Container className="introduction-container ">
        <Row>
          <Col className="introduction-text-col col-lg-4 order-12 order-lg-1">
            <div style={{ display: "flex", flexDirection: "row" }}>
              <h3 className="introduction-text-heading">nginr</h3>
              <h6 className="copyright-text">®</h6>
            </div>
            <h5 className="introduction-text">
              {getLanguageFile(state.language).Nginr.nginrMainDescription}
            </h5>
          </Col>
          <Col className="introduction-img-col col-lg-8 col-12 order-1 order-lg-12">
            <Image src={NGINR_IMG} className="introduction_img" />
          </Col>
        </Row>
      </Container>
      <Container className="features-container">
        <p className="features-text" style={{ marginTop: "80px" }}>
          {getLanguageFile(state.language).Nginr.nginrDescriptionPartOne}
        </p>
        <p className="features-text">
          {getLanguageFile(state.language).Nginr.nginrDescriptionPartTwo}
        </p>
        <p className="features-text">
          {getLanguageFile(state.language).Nginr.nginrDescriptionPartThree}
        </p>
        <p className="features-text">
          <h4><b><center>{TextFormater(" nginr<0>®</0>s: Integrated Security Data Processing and Information Fusion ")} </center></b></h4><br/>
<p>{TextFormater("nginr<0>®</0>s is an integrated data processing and information fusion system made up of various components including the following:")} <br/></p>
<p>
  <ul>
<li>  {TextFormater(" Engine for recognition and fusion (nginr<0>®</0>) is the core of nginr<0>®</0>s. It is based on blocknetwork, which is our technology patented in many European, Eurasian countries and the US. It detects patterns and scenarios, recognizes incidents and employs communications enabled services to follow the procedures and contingency plans. It also validates the accuracy of the fused information. ")} </li>
<center><img src={NGINRS_IMG} style={{width: '500px', height:'300px'}} /> </center><br/>
<p><center>{TextFormater("Figure 2 nginr<0>®</0>s Architecture.")}</center></p>
<li> 	Fence intrusion detection system is an accelerometer-based intrusion detection system. It is a network of various microsensors including accelerometers, gyros, passive infrared detectors and micro cameras. It detects and reports the intruders that attempt going over, through or under a fence. </li>
<li> 	{TextFormater("Surveillance cameras or surveillance camera management software can be integrated with nginr<0>®</0>s. State of the art deep machine learning modules in nginr<0>®</0>s recognize and identify people, vehicles, incidents, locations, safety equipment (goggles, masks, helmet, vest, etc.), objects, weapons, etc. ")}</li>
<li>    {TextFormater("nginr<0>®</0>s is integrated with access control systems of various types, such as card readers, palm vein scanners, fingerprint readers. ")}</li>
<li> 	{TextFormater("Data from security information and event management (SIEM) systems can be fused into nginr<0>®</0>s knowledgebase.")}
</li>
<li>   Information is retrieved from open sources including news sites and fused with the information collected from the other sensors.</li>
<li>   {TextFormater("Data in various types and formats can be uploaded to nginr<0>®</0>s.")}</li>
</ul>
</p>
<p> {TextFormater("nginr<0>®</0>s is a distributed, reliable and high-performance system that utilizes advanced artificial intelligence, microsensor, sensor networking, Internet of Things, blockchain and blocknetwork technologies to provide many new functionalities not available in the state-of-the-art security and safety systems. It can collect and process data from various sources including but not limited to:")} <br/><br/>
<ul>
<li>  Card readers, fingerprint readers, palm vein scanners and other access control technologies, </li>
<li>  Close circuit camera systems, </li>
<li>  Open-source searchers and news site interfaces, </li>
<li>  Cyber security systems,</li>
<li>  Security information and event management (SIEM) systems,</li>
<li>Fence intrusion detection systems. </li>
</ul>
</p>
<p>
Advanced artificial intelligence schemes are used to retrieve the information from the data provided by these and many other types of data collectors, validate their accuracy and fuse the information into a blocknetwork. 
</p>
<h5 style={{color:'black'}}> <b>{TextFormater("nginr<0>®</0>s fence intrusion detection system ")}</b></h5>
<p>{TextFormater("nginr<0>®</0>s has a fence intrusion detection system, which can be procured separately. Any module of nginr<0>®</0>s can be used alone or with any combination of the other modules. Fence intrusion detection system can detect and classify the intruders that attempt to go over, under or through a fence, inform the security staff about the location and the nature of the intrusion, and provide them with the pictures of the intruders. It also can turn and focus the surveillance cameras around the location to the point of intrusion. It differentiates the noise created by various effects, such as, a ball hits the fence, wind blows, someone leans on the fence, from the actual intruders. Therefore, the false alarm rate of the system is extremely low.")} </p>
<p>The system is made up of sensing nodes and collecting nodes. Sensing nodes has passive infrared detectors (PID), micro cameras, gyros, accelerometers, temperature and humidity sensors. PID detects the people close to the fence and triggers the node which takes a picture. If the person attempts to go over, under or through the fence, the node reports the intrusion with an attached picture to the security staff.</p>
<p>{TextFormater("nginr<0>®</0>s is highly fault tolerant. In addition to the external power, the nodes have rechargeable battery which suffices to run a week when the external power source is cut. They use a resilient wireless ad hoc networking protocol. When a subset of sensing nodes are tampered or destroyed, the other nodes continue reporting by finding an alternative route autonomously.")} </p>
<p>When the data reach the collecting nodes, they can be passed to the central system via various means including wired local area networks (LAN), wireless LANs, 2G, 3G and 4G networks.</p>
<p>{TextFormater("The components of the system can work in extreme weather conditions and temperatures between -40<0>o</0>C and +80<0>o</0>C. ")}</p><br/>

<h5 style={{color:'black'}}><b>{TextFormater("nginr<0>®</0>s video analytics ")}</b></h5>
<p>{TextFormater("nginr<0>®</0>s can interact many video analytics software and video management tools using their standard interfaces, and fuse the information provided by them into a blocknetwork. Additionally, nginr<0>®</0>s employ deep machine learning techniques for the following image and video analytics: ")}</p>
<p>
  <ul>
<li>	Detect and categorize humans</li>
<li>	Face recognition and identification</li>
<li>	Detect and identify of various incidents including fires, explosions, burglary, homicide, riots and demonstrations </li>
<li>	Detect crowds and count people </li>
<li>	Estimate the height and width of the objects and people </li>
<li>	Detect and distinguish the brand, model and color of cars </li>
<li>	Plate recognition</li>
<li>	Location and artifact recognition</li>
<li>	Detect and categorize arms, e.g., knife, pistol, rifle.</li>
<li>	Detect and categorize objects of interests, e.g., helmet, mask, balaclava, vest.</li>
</ul>
</p>
<br/>
<h5 style={{color:'black'}}><b>{TextFormater("nginr<0>®</0>s natural language processing ")}</b></h5>
<p>{TextFormater("nginr<0>®</0>s uses natural language processing (NLP) to understand the subject and perspective of textual data. It supports various formats including pdf, MS Word, etc. NLP is trained to detect various aspects including the following in text data:")} </p>
<p>
  <ul>
<li>	Relation to an interest, e.g., person, vehicle, location, organization </li>
<li>	Relation to a topic of interest </li>
<li>	Types of interactions among the interests </li>
<li>	Negative or positive </li>
</ul>
</p>
<br/>
<h5 style={{color:'black'}}><b>{TextFormater("nginr<0>®</0>s open source searcher ")}</b></h5>
<p>{TextFormater("nginr<0>®</0>s has an open source searcher that crawls over the Internet and search for the interests. It also interacts with the news sites to collect textual, audio or imagery data. It can be configured to periodically check a given list of sites. It filters and retrieves information from the collected data by using video analytics and NLP modules. ")} </p><br/>
<h5 style={{color:'black'}}><b>{TextFormater("nginr<0>®</0>s knowledgebase and communications enabled services ")}</b></h5>
<p>The information retrieved from the collected data are fused into a knowledgebase which is an implementation of our patented blocknetwork technology. Each interest has a blockchain made up of blocks in a blocknetwork. Each block lists the interests related to a data file. When a block is related to multiple interests, it indicates interaction among the interests, and that block becomes a part of multiple blockchains.</p>
<p>When blocks are fused into blockchains, the patterns of mobility, communications, financial transactions and social interactions are learned. A block that does not fit to the learned patterns is not fused unless it is approved by a system administrator.</p>
<p>Scenarios can be defined by the system administrators, who can also set the procedure to tackle with the scenarios. When a scenario is detected, the system sends the notifications and warnings and follow the reactions of the system users according to the defined procedures.</p><br/>

<h5 style={{color:'black'}}><b>{TextFormater("nginr<0>®</0>s use case scenarios ")}</b></h5>
<p>{TextFormater("These features enable nginr<0>®</0>s to be applied in many security and safety related fields. Example use case scenarios include but not limited to the following: ")} </p>
<p>
  <ul>
<li>	Incidents, such as, burglary, homicide, terror attack, can be autonomously detected, the security forces are informed, the preceding whereabouts of the ones involved in the incident are searched, and they are tracked by the system, which informs the security forces promptly. </li>
<li>	Fires and explosions are detected in data from security cameras and other sensors, the first responders are informed about the location and status of the incident and provided with the live and recorded pictures and videos, as well as the people and their status in the safe or danger zones. Information is provided in the instructed frequency and promptly.</li>
<li>	Fence intrusion detection system detects intrusions through the perimeter by using various types of microsensors and cameras. The security staff is warned about the intrusion and its location, and they are provided with the picture of the intruder(s).</li>
<li>	The regulations about the use of safety equipment, such as helmets, masks, vests are enforced by monitoring and reporting violations.</li>
<li>	Work process violations are detected and reported autonomously. The scenarios such as the following can be detected and reported: a visitor goes to the production area without an escort or the visitor continuously visit a department which he/she is not supposed to.</li>
<li>	The system detects and reports inconsistencies, such as a person who is not in the facility access to an electronic system in the facility, the color or brand of a car does not match to the plate number, the height of a person is different from the records, the identity and the card of the person does not match</li>
</ul>
</p>
<p>{TextFormater("Integrated security and safety system nginr<0>®</0>s is ready to provide advanced services with its patented technology. Security forces, health services, fire brigades, the premises of critical and large industrial organizations, military or civilian campuses with challenging security and safety requirements, ports, airports, train stations are among the examples to where nginr<0>®</0>s can be deployed. ")} </p>
        </p>
        <p className="features-text">
        {getLanguageFile("en").Nginr.nginrDescriptionPartFour}
          {/* {Texttranslation("Please click <1>here</1> to see the nginr<0>®</0>m booklet.","nginrs")} */}
        </p>
      </Container>
      <Footer />
    </Container>
  )
}

export default NginrsPage
